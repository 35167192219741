<template>
  <div class="navigation-detail">
    <!-- 基础信息部分 -->
    <base-headline title="基本信息" class="headline" />
    <el-table class="zwx-table zwx-table-detail" style="width: 100%;" row-key="key" :data="dataList" :show-header="false" border :stripe="false">
      <el-table-column prop="key" width="100" header-align="right" align="right"></el-table-column>
      <el-table-column prop="value" header-align="left" align="left">
        <template slot-scope="scope">
          <template v-if="scope.row.key == '菜单图标：' || scope.row.key == '菜单图片：'">
            <el-button class="zwx-button zwx-button-text-26" type="text" style="margin-right:12px" @click="openFilePreview('附件', scope.row.value)">
              <i class="outline chis-icon-outline-doc-search1" style="margin-right: 4px" />
              查看
            </el-button>
          </template>
          <template v-else>
            {{scope.row.value}}
          </template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'NavigationDetailIndex',
  data() {
    return {
      api: this.$store.state.api,
      users: this.$store.state.users,
      rid: this.$route.params.rid,
      menuTypes: ['-', '内部菜单', '外部菜单'],
      dataList: [],
    }
  },
  created() {},
  mounted() {
    this.detail()
  },
  computed: {},
  methods: {
    detail() {
      this.$emit('loading', true)
      let data = {
        rid: this.rid,
      }
      this.$system.get(this.api + '/systematic/getNavigation-1', data, true, true, this.detailSuccess, this.error)
    },
    detailSuccess(data) {
      if (data.type === '00') {
        this.dataList.push({ key: '功能类型', value: data.tdModuleNavigation.moduleCodeName })
        this.dataList.push({ key: '功能描述', value: data.tdModuleNavigation.moduleName })
        this.dataList.push({ key: '菜单标题', value: data.tdModuleNavigation.menuTitle })
        this.dataList.push({ key: '菜单类型', value: this.menuTypes[data.tdModuleNavigation.menuType] })
        this.dataList.push({ key: '菜单描述', value: data.tdModuleNavigation.menuDesc })
        this.dataList.push({ key: '菜单图标：', value: data.tdModuleNavigation.menuIcon })
        this.dataList.push({ key: '菜单图片：', value: data.tdModuleNavigation.menuPicture })
        this.dataList.push({ key: '跳转地址：', value: data.tdModuleNavigation.linkUrl })
        this.dataList.push({ key: '状态：', value: data.tdModuleNavigation.ifEnable == true ? '启用' : '禁用' })
        this.$emit('loading', false)
      } else if (data.type === '99') {
        this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
        this.$emit('loading', false)
      }
    },
    error() {
      this.$system.notify('错误', '网络连接失败', 'error')
      this.$emit('loading', false)
    },

    /**
     * 单个文件预览
     */
    openFilePreview(filename, filePath) {
      let previewFileList = []
      let item = {
        fileName: filename,
        filePath: filePath,
      }
      previewFileList.push(item)
      this.openFilePreview1(previewFileList, 0)
    },

    /**
     * 多个文件预览
     */
    openFilePreview1(fileList, index) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .zwx-headline .zwx-head-title{
    border-top: 0px !important;
}
</style>


