var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "navigation-detail" },
    [
      _c("base-headline", {
        staticClass: "headline",
        attrs: { title: "基本信息" }
      }),
      _c(
        "el-table",
        {
          staticClass: "zwx-table zwx-table-detail",
          staticStyle: { width: "100%" },
          attrs: {
            "row-key": "key",
            data: _vm.dataList,
            "show-header": false,
            border: "",
            stripe: false
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "key",
              width: "100",
              "header-align": "right",
              align: "right"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "value", "header-align": "left", align: "left" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.key == "菜单图标：" ||
                    scope.row.key == "菜单图片："
                      ? [
                          _c(
                            "el-button",
                            {
                              staticClass: "zwx-button zwx-button-text-26",
                              staticStyle: { "margin-right": "12px" },
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.openFilePreview(
                                    "附件",
                                    scope.row.value
                                  )
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "outline chis-icon-outline-doc-search1",
                                staticStyle: { "margin-right": "4px" }
                              }),
                              _vm._v(" 查看 ")
                            ]
                          )
                        ]
                      : [_vm._v(" " + _vm._s(scope.row.value) + " ")]
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }